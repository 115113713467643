/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import CTASection from '../../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Feature } from '../../components/Feature'
import Block from '../../components/Block'
import Page from '../../components/Page'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import Customers from '../../components/CustomerLogos'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import { LeadHeading } from '../../components/LeadHeading'
import { Link } from 'gatsby'
import MetaImage from '../../assets/images/waydev-meta.png'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const SwarmiaVsWaydev = () => {
  const SwarmiaVsWaydevImages = useStaticQuery(graphql`
    query {
      feature1: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-flow-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-investment-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Waydev vs. Swarmia"
      description="Explore the main differences between Waydev and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../../assets/images/backgrounds/swarmia-space.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>Waydev vs. Swarmia</h1>
          <div className="hero-description">
            <p className="large_text">
              Long story short, Waydev is a great tool for getting a high-level,
              managerial overview of engineering performance. But if you&#39;re
              looking to use a healthy set of metrics to drive positive change
              in the engineering organization, Swarmia may well be a better fit.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="layout-index">
        <Block size="large" type="padding-bottom-small">
          <LeadHeading
            title="What are the main differences between Swarmia and Waydev?"
            description="While Waydev is a dashboard for the engineering leadership, Swarmia is focused on using healthy metrics as conversation starters and driving concrete improvements at every level of the engineering organization."
          />
          <div className="features full-width-images">
            <Feature
              type="feature feature-grid-right"
              heading="Not just a dashboard"
              image={
                SwarmiaVsWaydevImages.feature1.childImageSharp.gatsbyImageData
              }
              imageAlt="Not just a dashboard"
            >
              <p>
                Waydev offers a high-level overview of engineering metrics. The
                problem is that at the end of the day, numbers alone don&#39;t
                drive change.
              </p>
              <p>
                That&#39;s why in addition to research-backed engineering
                metrics, Swarmia offers engineering teams tools that help them
                improve their ways of working over time. From Slack
                notifications to Working Agreements, Swarmia surfaces insights
                so you can start conversations and drive action.
              </p>
              <p>
                <Link to="/product/working-agreements/">
                  Read more about Working Agreements →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="No harmful metrics"
              image={
                SwarmiaVsWaydevImages.feature2.childImageSharp.gatsbyImageData
              }
              imageAlt="No harmful metrics"
            >
              <p>
                Most software developers have a hard time adopting any tool that
                tracks their activity. And it&#39;s no wonder, since most
                “engineering management“ platforms, including Waydev, are built
                on the premise that engineering productivity requires keeping
                tabs on individual developers.
              </p>
              <p>
                At Swarmia, we believe that productivity is a systemic issue.
                And instead of trying to fix it at the individual level, we
                focus on measuring and improving it at the team level.
                That&#39;s why you won&#39;t find harmful metrics or
                leaderboards in Swarmia.
              </p>
              <p>
                <Link to="/developers/">
                  Read more about Swarmia for developers →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Going beyond aggregate metrics"
              image={
                SwarmiaVsWaydevImages.feature3.childImageSharp.gatsbyImageData
              }
              imageAlt="Going beyond aggregate metrics"
            >
              <p>
                The problem with many software development dashboards, including
                Waydev, is that they only give you aggregate data. While that
                might be sufficient for C-level reporting, it doesn&#39;t help
                you understand what&#39;s contributing to the high-level metrics
                or fix any underlying issues.
              </p>
              <p>
                With Swarmia, you can drill down into any aggregate metric to
                quickly understand what it consists of and how to improve it in
                practice.
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="alex4" />
        <Customers />
      </Page>
      <CTASection>
        Swarmia is how the best engineering teams get better. Request a demo
        today.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/git-analytics/',
          '/blog/measuring-software-development-productivity/',
          '/blog/ship-software-10x-faster/',
        ]}
        heading="More from our blog"
      />
    </Layout>
  )
}

export default SwarmiaVsWaydev
